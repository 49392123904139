<template>
  <material-dialog
    v-model="dialog"
    title="匯出風險類型與項目版本"
    max-width="500"
  >
    <material-select
      v-model="nowYear"
      prependTitle="選擇年度"
      label="選擇年度"
      :items="versionList"
      item-text="year"
      item-value="year"
      @change="selectYear"
    ></material-select>
    <v-select
      v-model="nowVersion"
      :items="noList"
      label="選擇版本"
      outlined
      class="material-select mb-3"
      hide-details
      single-line
      dense
      :menu-props="{ offsetY: true }"
      :disabled="yearNoData === null || Object.keys(yearNoData).length === 0"
    >
      <template #prepend>
        <div class="text-no-wrap mr-2">選擇版本</div>
      </template>
      <template #append-outer>
        <v-btn
          class="align-center"
          @click="selectVersion"
          outlined
          color="primary"
        >
          選擇
        </v-btn>
      </template>
      <template #selection>
        {{ versionText(nowVersion) }}
      </template>
    </v-select>
    <p>
      已選擇 <span class="primary--text"> {{ selected.length }}</span> 個版本
    </p>
    <chip-grey v-for="(i, idx) of selected" :key="idx" class="mr-1"
      >{{ i.year }}-{{ versionText(i.no) }}</chip-grey
    >
    <template #actions>
      <v-spacer></v-spacer>
      <btn-cancel @click="closeDialog">取消</btn-cancel>
      <v-btn
        color="primary"
        depressed
        @click="exportSelect"
        :disabled="selected.length == 0"
      >
        匯出
      </v-btn>
    </template>
  </material-dialog>
</template>
<script>
import { apiGetVerions, apiGetRiskType } from "../../../api/risk-type";
import { versionMixin } from "../../../helper";
export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  mixins: [versionMixin],
  data() {
    return {
      versionList: [],
      noList: [],
      yearNoData: {},
      nowVersion: "",
      selected: [],
      nowYear: null,
    };
  },
  mounted() {
    this.defaultYearList();
  },
  methods: {
    nowVersionHandler(val) {
      this.nowVersion = val;
    },
    selectVersion() {
      if (!this.nowVersion) return;
      if (this.selected.findIndex((el) => el == this.nowVersion) != -1) return;
      this.selected.push({
        year: this.nowYear,
        no: this.versionText(this.nowVersion),
      });
    },
    closeDialog() {
      this.$emit("close");
      this.selected = [];
      this.nowVersion = "";
      this.nowYear = null;
    },
    selectYear() {
      this.noList = this.yearNoData[this.nowYear];
    },
    async defaultYearList() {
      try {
        let res = await apiGetVerions();
        this.versionList = res.data;
        let uniqueYear = new Set();
        let uniqueList = res.data.filter((element) => {
          const isDuplicate = uniqueYear.has(element.year);
          uniqueYear.add(element.year);

          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        this.versionList = uniqueList;
        this.defaultNoList();
      } catch (error) {
        console.log(error);
      }
    },
    async defaultNoList() {
      try {
        let res = await apiGetRiskType({ page: 1, size: 999 });
        for (let item of res.data.content) {
          if (this.yearNoData[item.year] == undefined) {
            this.yearNoData[item.year] = [item.no];
          } else {
            this.yearNoData[item.year].push(item.no);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    exportSelect() {
      this.$emit("export", this.selected);
    },
  },
};
</script>
<style scoped lang="scss">
.material-select {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
    background: #fff;
  }
  &:deep(.v-input__prepend-outer) {
    color: #222;
    div {
      min-width: 65px;
    }
  }
}
</style>
