<template>
  <v-container fluid>
    <div class="d-flex justify-space-between mb-5">
      <div class="d-flex align-baseline">
        <h1 class="text-h1">風險類型與項目</h1>
        <v-btn text @click="isExpand = !isExpand" class="ml-2"
          ><v-icon>mdi-filter-variant</v-icon><span class="ml-1">篩選</span>
        </v-btn>
      </div>
      <div class="d-flex align-baseline">
        <v-switch
          v-if="rolesCheck('riskTypeCancel')"
          v-model="cancelHandler"
          color="error"
          class="ma-0"
          hide-details
        >
          <template #label>
            <div class="text-no-wrap caption">取消版本</div>
          </template>
        </v-switch>
        <v-btn
          v-if="rolesCheck('riskTypeExport')"
          @click="beforeExport"
          depressed
          color="primary"
          class="ml-2"
          >匯出</v-btn
        >
        <v-btn
          v-if="rolesCheck('riskTypeAdd')"
          @click="beforeAdd"
          depressed
          color="primary"
          class="ml-2"
          :disabled="canVersionAdd == false"
          >新增版本</v-btn
        >
      </div>
    </div>

    <!-- <div class="d-flex">
      <div class="mr-5"> -->
    <material-filter
      :isExpand="isExpand"
      @filter="filterChoose"
    ></material-filter>
    <!-- </div>
      <div style="width: 100%"> -->
    <material-table
      :headers="headers"
      :items="tableData"
      :options.sync="options"
    >
      <template #no="{ item }">
        {{ versionText(item.no) }}
      </template>

      <template #status="{ item }">
        <chip-status small :status="item.status"></chip-status>
      </template>
      <template #updatedAt="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>
      <template #versioningAt="{ item }">
        {{ dateFormate(item.versioningAt) }}
      </template>
      <template #createdAt="{ item }">
        {{ dateFormate(item.createdAt) }}
      </template>
      <template #actions="{ item }">
        <div class="d-flex">
          <v-icon @click="goDetail(item)" class="mr-3 pointer">mdi-eye</v-icon>
          <v-icon
            v-if="rolesCheck('riskTypeDelete')"
            @click="deleteVersion(item)"
            class="pointer"
            :disabled="![0, 1].includes(item.status) == true"
            >mdi-delete</v-icon
          >
          <div
            v-if="item.status != 4"
            @click="cancelVersion(item)"
            v-show="cancelHandler"
          >
            <v-btn text color="error">取消</v-btn>
          </div>
          <div v-else @click="recoverVersion(item)" v-show="cancelHandler">
            <v-btn text color="primary">復原</v-btn>
          </div>
        </div>
      </template>
    </material-table>
    <material-pagination
      :totalPages="totalPages"
      @pageFilter="pageFilter"
    ></material-pagination>
    <!-- :data-class="'noTrColor'" -->
    <!-- </div> -->
    <!-- </div> -->

    <!-- Dialogs -->
    <!-- 新增公版版本 -->
    <material-dialog
      v-model="addPublicDialog"
      title="新增風險類型與項目版本"
      max-width="400"
    >
      新增風險類型與項目版本號
      <chip-grey class="ml-2">{{ addYear }}-{{ versionText(addNo) }}</chip-grey>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="addPublicDialog = false">取消</btn-cancel>
        <v-btn @click="addRiskType()" depressed color="primary">確認</v-btn>
      </template>
    </material-dialog>
    <!-- 匯出公版版本 -->
    <export-risk-type-dialog
      :dialog="exportPublicDialog"
      @close="exportPublicDialog = false"
      @export="exportData"
    ></export-risk-type-dialog>
    <!-- 刪除公版版本 -->
    <material-dialog
      title="刪除風險類型與項目版本"
      v-model="deleteAlert"
      max-width="500"
    >
      您確定要刪除風險類型與項目版本
      <chip-grey>
        {{ `${nowItem.year}-${versionText(nowItem.no)}` }}
      </chip-grey>
      嗎？
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteSubmit" depressed>刪除版本</v-btn>
        <btn-cancel @click="deleteAlert = false">保留版本</btn-cancel>
      </template>
    </material-dialog>
    <!-- 取消公版版本 -->
    <material-dialog
      title="取消風險類型與項目版本"
      v-model="cancelAlert"
      max-width="500"
    >
      您確定要取消風險類型與項目版本
      <chip-grey>
        {{ `${nowItem.year}-${versionText(nowItem.no)}` }}
      </chip-grey>
      嗎？
      <alert-card
        >狀態更改為取消後，引用此版本之單位稽核領域、風險評估、稽核計畫相關版本將自動變更狀態為取消，並不可進行編輯</alert-card
      >
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancelSubmit" depressed>取消版本</v-btn>

        <btn-cancel @click="cancelAlert = false">保留版本</btn-cancel>
      </template>
    </material-dialog>
    <!-- 復原公版版本 -->
    <material-dialog
      v-model="recoverAlert"
      title="復原風險類型與項目版本"
      max-width="400"
    >
      復原風險類型與項目版本號
      <chip-grey class="ml-2">{{
        `${nowItem.year}-${versionText(nowItem.no)}`
      }}</chip-grey>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="recoverAlert = false">取消</btn-cancel>
        <v-btn @click="recoverSubmit" depressed color="primary">確認</v-btn>
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import ExportRiskTypeDialog from "./components/ExportRiskTypeDialog.vue";
import AlertCard from "./components/AlertCard.vue";
import MaterialPagination from "../../components/MaterialPagination.vue";
import { dateFormateMixin, versionMixin, checkRolesMixin } from "../../helper";
import {
  apiGetRiskType,
  apiGetVerions,
  apiGetNextVersion,
  apiDeleteRiskType,
  apiCancelRiskType,
  apiRecoverRiskType,
  apiAddRiskType,
  apiExportRiskType,
} from "../../api/risk-type";

export default {
  components: { ExportRiskTypeDialog, AlertCard, MaterialPagination },
  name: "AuditFieldTable",
  mixins: [dateFormateMixin, versionMixin, checkRolesMixin],
  data() {
    return {
      headers: [
        {
          text: "年度",
          align: "start",
          sortable: false,
          value: "year",
        },
        { text: "版本", value: "no", sortable: false },
        { text: "狀態", value: "status", sortable: false },
        { text: "更新時間", value: "updatedAt" },
        { text: "定版時間", value: "versioningAt" },
        { text: "建立時間", value: "createdAt" },
        { text: "操作", value: "actions", sortable: false, width: 100 },
      ],
      tableData: [
        // {
        //   id: 1,
        //   annual: "2022",
        //   version: "02",
        //   status: "待審(副總稽核)",
        //   updatedAt: new Date(),
        //   versioningAt: new Date(),
        //   createdAt: new Date(),
        // }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [false],
      },
      cancelHandler: false,
      nowItem: {
        year: "",
        no: "",
      },
      //dialogs
      addPublicDialog: false,
      exportPublicDialog: false,
      deleteAlert: false,
      cancelAlert: false,
      recoverAlert: false,
      //filter
      isExpand: false,
      filterData: null,
      // pagination
      totalPages: 1,
      // 可否新增版本
      canVersionAdd: false,
      addYear: null,
      addNo: null,
      page: 1,
      itemsPerPage: 10,
    };
  },
  async mounted() {
    await this.getNextVersion();
    await this.getRiskType(this.options);
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.getRiskType();
      },
    },
  },

  methods: {
    // 取得風險類型與項目列表
    async getRiskType() {
      try {
        let searchData = this.makeUrlData();
        let res = await apiGetRiskType(searchData);
        this.tableData = res.data.content;
        this.totalPages = res.data.totalPages;
      } catch (err) {}
    },

    // 確認是否能新增版本
    async getNextVersion() {
      try {
        let res = await apiGetNextVersion();
        this.canVersionAdd = res.data.next;
        this.addYear = res.data.year;
        this.addNo = res.data.no;
        // this.canVersionAdd = true;
      } catch (error) {
        this.canVersionAdd = false;
      }
    },
    goDetail(item) {
      let attachData = {
        year: item.year,
        no: item.no,
        updatedAt: item.updatedAt,
        status: item.status,
      };
      this.$router.push({
        path: `/audit-field/risk-type/${item.id}`,
        // query: attachData,
      });
    },
    // 詢問是否刪除版本
    deleteVersion(item) {
      this.nowItem = item;
      console.log(this.nowItem);
      this.deleteAlert = true;
    },
    // 確定刪除版本
    async deleteSubmit() {
      try {
        console.log("delete");
        let res = await apiDeleteRiskType(this.nowItem["id"]);
        this.getRiskType();
        console.log(res);
        this.deleteAlert = false;
      } catch (error) {}
    },
    // 詢問是否取消版本
    cancelVersion(item) {
      this.nowItem = item;
      this.cancelAlert = true;
    },
    // 確定取消版本
    async cancelSubmit() {
      try {
        console.log("cancel");
        let res = await apiCancelRiskType(this.nowItem["id"]);
        this.cancelAlert = false;
        this.getRiskType();
        console.log(res);
      } catch (error) {}
    },
    // 復原版本
    recoverVersion(item) {
      this.nowItem = item;
      this.recoverAlert = true;
    },
    // 確定復原版本
    async recoverSubmit() {
      try {
        let res = await apiRecoverRiskType(this.nowItem["id"]);
        this.recoverAlert = false;
        this.getRiskType();
      } catch (error) {
        console.log(error);
      }
    },
    async addRiskType() {
      try {
        let addData = {
          year: this.addYear,
          no: this.addNo,
        };
        let res = await apiAddRiskType(addData);
        this.getRiskType();
        this.addPublicDialog = false;
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 過濾元件
    filterChoose() {
      this.filterData = arguments[0];
      console.log(this.filterData);
      this.getRiskType();
    },
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      console.log(arguments[0]);
      this.page = pageParams.page;
      this.itemsPerPage = pageParams.itemsPerPage;
      this.getRiskType();
    },
    // 新增版本提示
    beforeAdd() {
      this.addPublicDialog = true;
      this.addYear = "2022";
      this.addNo = "02";
    },
    // 匯出選擇
    async beforeExport() {
      this.exportPublicDialog = true;
    },
    // 匯出
    async exportData() {
      try {
        let data = arguments[0];
        this.exportPublicDialog = false;
        let res = await apiExportRiskType(data);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 建立過濾網址參數
    makeUrlData() {
      let searchData = {};
      // searchData["page"] = this.options.page;
      searchData["page"] = this.page;
      searchData["size"] = this.itemsPerPage;
      searchData["sortBy"] = [null, undefined, "", "updatedAt"].includes(
        this.options.sortBy[0]
      )
        ? "updatedAt"
        : this.options.sortBy[0];
      searchData["order"] = this.options.sortDesc[0] == true ? "asc" : "desc";

      if (this.filterData != null) {
        // 加入 filterData status
        if (![undefined, null, ""].includes(this.filterData.status)) {
          searchData["status"] = this.filterData.status;
        }
        // 加入 start
        if (![undefined, null, ""].includes(this.filterData.start)) {
          searchData["start"] = this.filterData.start;
        }
        // 加入 end
        if (![undefined, null, ""].includes(this.filterData.end)) {
          searchData["end"] = this.filterData.end;
        }
      }
      return searchData;
    },
  },
};
</script>
<style scoped lang="scss"></style>
